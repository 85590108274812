<template>
	<div>
		<s-crud
			title="Configuracion Congelado"
			:config="config"
			:filter="filter"
			add
			@save="save($event)"
			edit 
			remove
			search-input
		> 
			<template v-slot="props">
				<v-container>
                    <v-row>
                        <v-col cols="6" lg="6" md="6" sm="12">
							<s-switch
								v-model="props.item.CgfValidateHeavy"
								label="Validar pesado"
							>			
							</s-switch>
                        </v-col>
                        <v-col cols="6" lg="6" md="6" sm="12">
                            <s-switch
								v-model="props.item.CgfValidateCratesForeman"
								label="Validar jabas por capataz"
							>
							</s-switch>
                        </v-col>
                    </v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>



<script>
	import _sConfigurationFrozenService from "@/services/FrozenProduction/FrzConfigurationFrozenService.js"

	export default {
		data() {
			return {
				config: {
					model: {
						CgfID: "ID"
					},
					service: _sConfigurationFrozenService,
					headers: [
						{text: "ID", value: "CgfID"},
						{text: "validar pesado", value: "CgfValidateHeavyName"},
						{text: "validar jabas por capataz", value: "CgfValidateCratesForemanName"}

					]
				},
				filter: { TypeProcessMadName: 0},
			}
		},

		methods: {
			save(item) {
				item.save();
			},
		},
	}
</script>